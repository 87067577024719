import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import OldUI from "./pages/OldUI";
import Logs from "./pages/Logs";
import Queue from "./pages/Queue";
import { useState } from "react";

export default function App() {

  const [backendOrigin, setBackendOrigin] = useState('api.agent.exchange')

  const changeEnvironment = (e) => {
    if(e.target.value === 'staging') {
      setBackendOrigin('stg-api.agent.exchange')
    } else {
      setBackendOrigin('api.agent.exchange')
    }
  }

  return (
    <div>
      <BrowserRouter>
        <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
          <nav>
            <Link to='/'>Dashboard</Link>
            <Link to='/events/all-logs'>All Logs</Link>
            <Link to='/queue'>Queue</Link>
            <Link to='/old-ui'>Old UI</Link>
          </nav>
          <select onChange={e => changeEnvironment(e)}>
            <option value='production'>Production</option>
            <option value='staging'>Staging</option>
          </select>
        </div>
        <Routes>
          <Route path='/' element={<Dashboard backendOrigin={backendOrigin} />} />
          <Route path='/events/wallet/:address' element={<Logs backendOrigin={backendOrigin} />} />
          <Route path='/events/user/:address' element={<Logs backendOrigin={backendOrigin} />} />
          <Route path='/events/all-logs' element={<Logs backendOrigin={backendOrigin} />} />
          <Route path='/queue' element={<Queue backendOrigin={backendOrigin} />} />
          <Route path='/old-ui' element={<OldUI />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
