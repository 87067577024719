import axios from "axios";
import { useState, useEffect } from "react";

const truncateAddress = address => {
  return address?.slice ? address.slice(0,5) + '..' + address.slice(-3) : address
}

const formatDate = date => {
  return date?.slice ? date.slice(8, 10) + '/' + date.slice(5, 7) + ' ' + date.slice(11, 19) : date
}

export default function Queue({ backendOrigin }) {

  const [loadingData, setLoadingData] = useState(true)
  const [walletsData, setWalletsData] = useState({})

    useEffect(() => {
        let intervalId
        const getWalletsData = async () => {
          try {
            intervalId = setInterval(async () => {
              const { data } = await axios.get(`https://${backendOrigin}/admin/quest/dashboard`, {
                headers: {
                  Authorization: process.env.REACT_APP_ADMIN_KEY
                }
              })

              data.wallets.sort((a, b) => {
                return new Date(b.rewardsProcessedAt) - new Date(a.rewardsProcessedAt)
              })

              setWalletsData(data.wallets)
              setLoadingData(false)
            }, 5000)
          } catch (error) {
            setLoadingData(false)
            console.error('Error', error)
          }
        }
          getWalletsData()

          return () => {
            clearInterval(intervalId)
          }

      }, [backendOrigin])

  if(loadingData) return <p>Loading data...</p>

  return (
    <>
      <table border="1">
        <thead>
          <tr>
            <th>address</th>
            <th>currentOwner</th>
            <th>activationRewarded</th>
            <th>agentPoints</th>
            <th>balanceLastCheckedAt</th>
            <th>balanceUsdValue</th>
            <th>rewardsProcessedAt</th>
            <th>rewardsProcessedBalance</th>
            <th>nickname</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'center' }}>
          {
              walletsData.length > 0 && walletsData?.map(wallet => (
                  <tr key={wallet.address}>
                      <td>{truncateAddress(wallet.address)}</td>
                      <td>{truncateAddress(wallet.currentOwner)}</td>
                      <td>{wallet.activationRewarded ? 'true' : 'false'}</td>
                      <td>{wallet.agentPoints}</td>
                      <td>{formatDate(wallet.balanceLastCheckedAt)}</td>
                      <td>{wallet.balanceUsdValue}</td>
                      <td>{formatDate(wallet.rewardsProcessedAt)}</td>
                      <td>{wallet.rewardsProcessedBalance}</td>
                      <td>{wallet.nickname}</td>
                    </tr>
                ))
                }
            </tbody>
          </table>
    </>
  );
}