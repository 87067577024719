import axios from "axios";
import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

const truncateAddress = address => {
  return address?.slice ? address.slice(0,5) + '..' + address.slice(-3) : address
}

const formatDate = date => {
  return date?.slice ? date.slice(8, 10) + '/' + date.slice(5, 7) + ' ' + date.slice(11, 19) : date
}

const badges = {
  liquidity: [
    { id: 1, name: "Nemo", requiredTotalDepositInUSD: 300, xpGain: 1337 },
    { id: 2, name: "MoonFish", requiredTotalDepositInUSD: 15000, xpGain: 3000 },
    { id: 3, name: "Shark", requiredTotalDepositInUSD: 30000, xpGain: 4200 },
    { id: 4, name: "Whale", requiredTotalDepositInUSD: 60000, xpGain: 6900 },
  ],
  referral: [
    { id: 1, name: "Got Friends", requiredActiveFriends: 1, xpGain: 1337, xpMultiplierGain: 0.2 },
    { id: 2, name: "Friendly", requiredActiveFriends: 5, xpGain: 3000, xpMultiplierGain: 0.3 },
    { id: 3, name: "Some Friends", requiredActiveFriends: 15, xpGain: 4200, xpMultiplierGain: 1.5 },
    { id: 4, name: "Influencer", requiredActiveFriends: 50, xpGain: 6900, xpMultiplierGain: 2 },
  ],
  community: [
    { id: 1, name: "Undercover", xpGain: 1337 },
    { id: 2, name: "X", xpGain: 500 },
    { id: 3, name: "Discord", xpGain: 500 },
    { id: 4, name: "EthCC Brussels", xpGain: 500 },
    { id: 101, name: "OG", requiredDiscordRoleId: '1232743874577829899', xpGain: 500 },
    { id: 102, name: "Jimmy", requiredDiscordRoleId: '1260624380128526427', xpGain: 500 },
    { id: 103, name: "Greg", requiredDiscordRoleId: '1260624512521605150', xpGain: 500},
    // { id: 104, name: "Meme King", requiredDiscordRoleId: '000', xpGain: 500},
    // { id: 105, name: "Gamer", requiredDiscordRoleId: '000', xpGain: 500},
    // { id: 106, name: "XRaider", requiredDiscordRoleId: '000', xpGain: 500},
  ]
}

export default function Dashboard({ backendOrigin }) {

  const [loadingData, setLoadingData] = useState(true)
  const [questUsersAndWalletsLogs, setQuestUsersAndWalletsLogs] = useState({})

    useEffect(() => {
        let intervalId
        const getUsersAndWalletsWithQuestFields = async () => {
          try {
            intervalId = setInterval(async () => {
              const { data } = await axios.get(`https://${backendOrigin}/admin/quest/dashboard`, {
                headers: {
                  Authorization: process.env.REACT_APP_ADMIN_KEY
                }
              })
              
              let usersWithWallets = []

              data.users.forEach((user, index) => {
                usersWithWallets.push(user)
                usersWithWallets[index].walletsInfo = data.wallets.filter(wallet => wallet.currentOwner === user.address)
              })

              usersWithWallets.sort((a, b) => {
                return b.walletsInfo.length - a.walletsInfo.length
              })

              setQuestUsersAndWalletsLogs(usersWithWallets)
              setLoadingData(false)
            }, 5000)
          } catch (error) {
            setLoadingData(false)
            console.error('Error', error)
          }
        }
          getUsersAndWalletsWithQuestFields()

          return () => {
            clearInterval(intervalId)
          }
          
      }, [backendOrigin])

  if(loadingData) return <p>Loading data...</p>

  return (
    <>
      <table border="1">
        <thead>
          <tr>
            <th className="quest">user</th>
            <th>wallets</th>
          </tr>
        </thead>
        <tbody>
          {
              questUsersAndWalletsLogs.length > 0 && questUsersAndWalletsLogs?.map(user => (
                  <tr key={user.addr}>
                      <td>
                        <Link to={`/events/user/${user.address}`}>
                          <span className="td-data" title="user's eoa address">
                            {truncateAddress(user.address)}
                          </span>
                        </Link>
                        <span className="td-data" title="walletsBalanceUsdValue">{user.walletsBalanceUsdValue?.toFixed(2)}</span>
                        <span className="td-data" title="walletPoints">{user.walletPoints?.toFixed(2)}</span>
                        <br/>
                        <span className="td-data" title="xp">{user.xp?.toFixed(2)}</span>
                        <span className="td-data" title="multiplier">{user.multiplier?.toFixed(2)}</span>
                        <span className="td-data" title="xpMultiplier">{user.xpMultiplier?.toFixed(2)}</span>
                        {user.badges?.liquidity.length > 0 && (
                  <span className="badge badge-tvl">
                    {
                      badges?.liquidity?.find(
                        (b) => b.id === Math.max(...user.badges?.liquidity.map(el => el.id)),
                      )?.name
                    }
                  </span>
                )}
                {user.badges.referral.length > 0 && (
                  <span className="badge badge-ref">
                    {
                      badges.referral.find(
                        (b) => b.id === Math.max(...user.badges.referral.map(el => el.id)),
                      )?.name
                    }
                  </span>
                )}
                {user.badges.community.map((cb) => (
                  <span key={cb.id} className="badge badge-com">
                    {badges.community.find((b) => b.id === cb.id)?.name}
                  </span>
                ))}
                      </td>
                      {
                        user.walletsInfo?.map(wallet => (
                          <Fragment key={wallet.address}>
                            <td>
                              <Link to={`/events/wallet/${wallet.address}`}>
                                <span className="td-data" title="wallet address">
                                  {truncateAddress(wallet.address)}
                                </span>
                              </Link>
                              <span className="td-data" title="balanceUsdValue">{wallet.balanceUsdValue?.toFixed(2)}</span>
                              <span className="td-data" title="balanceLastCheckedAt">{formatDate(wallet.balanceLastCheckedAt)}</span>
                              <span className="td-data" title="agentPoints">{wallet.agentPoints?.toFixed(2)}</span>
                              <span className="td-data" title="rewardsProcessedBalance">{wallet.rewardsProcessedBalance?.toFixed(2)}</span>
                              <span className="td-data" title="rewardsProcessedAt">{formatDate(wallet.rewardsProcessedAt)}</span>
                            </td>
                          </Fragment>
                        ))
                      }
                    </tr>
                ))
                }
            </tbody>
          </table>
    </>
  );
}