import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const truncateAddress = address => {
  return address?.slice ? address.slice(0,5) + '..' + address.slice(-3) : address
}

const formatDate = date => {
  return date?.slice ? date.slice(8, 10) + '/' + date.slice(5, 7) + ' ' + date.slice(11, 19) : date
}

const Logs = ({ backendOrigin }) => {

    const params = useParams()
    const location = useLocation()

    const [logs, setLogs] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let intervalId
        const getAllLogs = async () => {
            try {
              intervalId = setInterval(async () => {
                const { data } = await axios.get(`https://${backendOrigin}/admin/quest/logs`, {
                  headers: {
                    Authorization: process.env.REACT_APP_ADMIN_KEY
                  }
                })

                let paginatedResults = []

                for(let i = 0; i < 20; i++) {
                  paginatedResults[i] = data[i]
                }

                setLogs(paginatedResults)
                setLoading(false)
              }, 5000)
            } catch (error) {
              console.error('Error', error)
              setLoading(false)
            }
          }

          const getUserLogs = async () => {
            try {
              intervalId = setInterval(async () => {
                const { data } = await axios.get(`https://${backendOrigin}/admin/quest/logs/user/${params.address}`, {
                  headers: {
                    Authorization: process.env.REACT_APP_ADMIN_KEY
                  }
                })
                setLogs(data)
                setLoading(false)
              }, 5000)
            } catch (error) {
              console.error('Error', error)
              setLoading(false)
            }
          }
        
          const getWalletLogs = async () => {
            try {
              intervalId = setInterval(async () => {
                const { data } = await axios.get(`https://${backendOrigin}/admin/quest/logs/wallet/${params.address}`, {
                  headers: {
                    Authorization: process.env.REACT_APP_ADMIN_KEY
                  }
                })
                setLogs(data)
                setLoading(false)
              }, 5000)
            } catch (error) {
              console.error('Error', error)
              setLoading(false)
            }
          }

          if(location.pathname.includes('user')) {
            getUserLogs()
          } else if (location.pathname.includes('wallet')) {
            getWalletLogs()
          } else {
            getAllLogs()
          }


          return () => {
            clearInterval(intervalId)
          }

          // eslint-disable-next-line
    }, [backendOrigin])

    if(loading) return <p>Loading data...</p>

    return (
        <div>
            <table>
                <thead>
                    <tr>
                      <th>timestamp</th>
                      <th>user</th>
                      <th>wallet</th>
                      <th>event</th>
                      <th>used Xp Multiplier</th>
                      <th>used Points Multiplier</th>
                      <th>gained Xp</th>
                      <th>gained Xp Multiplier</th>
                      <th>gained Points</th>
                      <th>gained Badge</th>
                      <th>xp After</th>
                      <th>xp Multiplier After</th>
                      <th>points After</th>
                      <th>used Wallet Balance</th>
                      <th>used Total User Balance</th>
                    </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                    {
                      logs.length > 0 && logs.map(log => (
                        <tr key={log._id}>
                          <td>{formatDate(log?.timestamp || log?.createdAt)}</td>
                          <td>{truncateAddress(log.user)}</td>
                          <td>{truncateAddress(log.wallet)}</td>
                          <td>{log.event}</td>
                          <td>{log.context?.before?.user?.xpMultiplier}</td>
                          <td>{log.context?.user?.multiplier}</td>
                          <td>{log.rewards?.user?.xp}</td>
                          <td>{log.rewards?.user?.xpMultiplier}</td>
                          <td>{log.rewards?.user?.walletPoints}</td>
                          <td>{log.rewards?.user?.badge?.id}</td>
                          <td>{log.context?.after?.user?.xp}</td>
                          <td>{log.context?.after?.user?.xpMultiplier}</td>
                          <td>{log.context?.after?.user?.walletPoints}</td>
                          <td>{log.context?.wallet?.balanceUsdValue}</td>
                          <td>{log.context?.user?.walletsBalanceUsdValue}</td>
                        </tr>
                      ))
                    }
                </tbody>
            </table>
        </div>
    )

}

export default Logs