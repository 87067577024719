import { useState, useEffect } from "react"
import axios from 'axios'

const BACKEND_ORIGIN = 'https://dev-api.agent.exchange'

const OldUI = () => {

  const [loadingData, setLoadingData] = useState(true)

  const [display, setDisplay] = useState('table')
  const [allLogs, setAllLogs] = useState([])
  const [userToSearch, setUserToSearch] = useState('')
  const [userLogs, setUserLogs] = useState([])
  const [walletToSearch, setWalletToSearch] = useState('')
  const [walletLogs, setWalletLogs] = useState([])
  const [questUsersAndWalletsLogs, setQuestUsersAndWalletsLogs] = useState([])

  const [showUserAddress, setShowUserAddress] = useState(true)
  const [showUserId, setShowUserId] = useState(false)
  const [showUserActivated, setShowUserActivated] = useState(true)
  const [showUserActiveReferrals, setShowUserActiveReferrals] = useState(true)
  const [showUserIsKOL, setShowUserIsKOL] = useState(true)
  const [showUserLevel, setShowUserLevel] = useState(true)
  const [showUserMultiplier, setShowUserMultiplier] = useState(true)
  const [showUserRank, setShowUserRank] = useState(true)
  const [showUserRankPercent, setShowUserRankPercent] = useState(true)
  const [showUserRefCode, setShowUserRefCode] = useState(true)
  const [showUserRewardedActiveReferrals, setShowUserRewardedActiveReferrals] = useState(false)
  const [showUserRewardedWalletActivations, setShowUserRewardedWalletActivations] = useState(false)
  const [showUserUserWalletPoints, setShowUserUserWalletPoints] = useState(true)
  const [showUserWalletsBalanceUsdValue, setShowUserWalletsBalanceUsdValue] = useState(true)
  const [showUserXp, setShowUserXp] = useState(true)
  const [showUserXpMultiplier, setShowUserXpMultiplier] = useState(true)
  const [showUserBadgesLiquidity, setShowUserBadgesLiquidity] = useState(false)
  const [showUserBadgesCommunity, setShowUserBadgesCommunity] = useState(false)
  const [showUserBadgesReferral, setShowUserBadgesReferral] = useState(false)

  const [showWalletAddress, setShowWalletAddress] = useState(true)
  const [showWalletId, setShowWalletId] = useState(false)
  const [showWalletCurrentOwner, setShowWalletCurrentOwner] = useState(true)
  const [showWalletActivationRewarded, setShowWalletActivationRewarded] = useState(true)
  const [showWalletWalletAgentPoints, setShowWalletWalletAgentPoints] = useState(true)
  const [showWalletBalanceUsdValue, setShowWalletBalanceUsdValue] = useState(true)
  const [showWalletNickname, setShowWalletNickname] = useState(true)
  const [showWalletRewardsProcessedBalance, setShowWalletRewardsProcessedBalance] = useState(true)

  const [showUser, setShowUser] = useState(true)
  const [showWallet, setShowWallet] = useState(true)
  const [showId, setShowId] = useState(false)
  const [showCreatedAt, setShowCreatedAt] = useState(false)
  const [showFunction, setShowFunction] = useState(false)
  const [showBalanceUsdValue, setShowBalanceUsdValue] = useState(true)
  const [showHeldMinutes, setShowHeldMinutes] = useState(true)
  const [showHeldMinutesGroups, setShowHeldMinutesGroups] = useState(true)
  const [showHeldSeconds, setShowHeldSeconds] = useState(true)
  const [showHeldValue, setShowHeldValue] = useState(true)
  const [showHeldValueGroups, setShowHeldValueGroups] = useState(true)
  const [showLastCheckedAt, setShowLastCheckedAt] = useState(false)
  const [showNewPoints, setShowNewPoints] = useState(true)
  const [showNewXp, setShowNewXp] = useState(true)
  const [showRewardsProcessedAt, setShowRewardsProcessedAt] = useState(false)
  const [showRewardsProcessedBalance, setShowRewardsProcessedBalance] = useState(true)
  const [showUserWalletPoints, setShowUserWalletPoints] = useState(true)
  const [showWalletAgentPoints, setShowWalletAgentPoints] = useState(true)
  const [showXp, setShowXp] = useState(true)

  useEffect(() => {
    const getAllLogs = async () => {
      try {
        const { data } = await axios.get(`${BACKEND_ORIGIN}/admin/quest/logs`, {
          headers: {
            Authorization: process.env.REACT_APP_ADMIN_KEY
          }
        })
        setAllLogs(data)
        setLoadingData(false)
      } catch (error) {
        console.error('Error', error)
        setLoadingData(false)
      }
    }
    getAllLogs()
  }, [])

  useEffect(() => {
    const getUsersAndWalletsWithQuestFields = async () => {
      try {
        while(true) {
          const { data } = await axios.get(`${BACKEND_ORIGIN}/admin/quest/dashboard`, {
            headers: {
              Authorization: process.env.REACT_APP_ADMIN_KEY
            }
          })
          setQuestUsersAndWalletsLogs(data)
        }
      } catch (error) {
        console.error('Error', error)
      }
    }
      getUsersAndWalletsWithQuestFields()
  }, [])

  const getUserLogs = async () => {
    try {
      const { data } = await axios.get(`${BACKEND_ORIGIN}/admin/quest/logs/user/${userToSearch}`, {
        headers: {
          Authorization: process.env.REACT_APP_ADMIN_KEY
        }
      })
      setUserLogs(data)
    } catch (error) {
      console.error('Error', error)
    }
  }

  const getWalletLogs = async () => {
    try {
      const { data } = await axios.get(`${BACKEND_ORIGIN}/admin/quest/logs/wallet/${walletToSearch}`, {
        headers: {
          Authorization: process.env.REACT_APP_ADMIN_KEY
        }
      })
      setWalletLogs(data)
    } catch (error) {
      console.error('Error', error)
    }
  }

  if(loadingData) return <p>Loading data...</p>

    return (
        <div className="container">
        <button onClick={() => display === 'table' ? setDisplay('columns') : setDisplay('table')}>{display === 'table' ? 'Display as Columns' : 'Display as Table'}</button>
        {
            display === 'table' ? (
              <div className="wrapper-table">

                <div className="filters">
                  <label>
                    address
                    <input type='checkbox' checked={showUserAddress} onChange={() => setShowUserAddress(prev => !prev)} />
                  </label>
                  <label>
                    _id
                    <input type='checkbox' checked={showUserId} onChange={() => setShowUserId(prev => !prev)} />
                  </label>
                  <label>
                    activated
                    <input type='checkbox' checked={showUserActivated} onChange={() => setShowUserActivated(prev => !prev)} />
                  </label>
                  <label>
                    activeReferrals
                    <input type='checkbox' checked={showUserActiveReferrals} onChange={() => setShowUserActiveReferrals(prev => !prev)} />
                  </label>
                  <label>
                    isKOL
                    <input type='checkbox' checked={showUserIsKOL} onChange={() => setShowUserIsKOL(prev => !prev)} />
                  </label>
                  <label>
                    level
                    <input type='checkbox' checked={showUserLevel} onChange={() => setShowUserLevel(prev => !prev)} />
                  </label>
                  <label>
                    multiplier
                    <input type='checkbox' checked={showUserMultiplier} onChange={() => setShowUserMultiplier(prev => !prev)} />
                  </label>
                  <label>
                    rank
                    <input type='checkbox' checked={showUserRank} onChange={() => setShowUserRank(prev => !prev)} />
                  </label>
                  <label>
                    rankPercent
                    <input type='checkbox' checked={showUserRankPercent} onChange={() => setShowUserRankPercent(prev => !prev)} />
                  </label>
                  <label>
                    refCode
                    <input type='checkbox' checked={showUserRefCode} onChange={() => setShowUserRefCode(prev => !prev)} />
                  </label>
                  <label>
                    rewardedActiveReferrals
                    <input type='checkbox' checked={showUserRewardedActiveReferrals} onChange={() => setShowUserRewardedActiveReferrals(prev => !prev)} />
                  </label>
                  <label>
                    rewardedWalletActivations
                    <input type='checkbox' checked={showUserRewardedWalletActivations} onChange={() => setShowUserRewardedWalletActivations(prev => !prev)} />
                  </label>
                  <label>
                    walletPoints
                    <input type='checkbox' checked={showUserUserWalletPoints} onChange={() => setShowUserUserWalletPoints(prev => !prev)} />
                  </label>
                  <label>
                    walletsBalanceUsdValue
                    <input type='checkbox' checked={showUserWalletsBalanceUsdValue} onChange={() => setShowUserWalletsBalanceUsdValue(prev => !prev)} />
                  </label>
                  <label>
                    xp
                    <input type='checkbox' checked={showUserXp} onChange={() => setShowUserXp(prev => !prev)} />
                  </label>
                  <label>
                    xpMultiplier
                    <input type='checkbox' checked={showUserXpMultiplier} onChange={() => setShowUserXpMultiplier(prev => !prev)} />
                  </label>
                  <label>
                    badgesLiquidity
                    <input type='checkbox' checked={showUserBadgesLiquidity} onChange={() => setShowUserBadgesLiquidity(prev => !prev)} />
                  </label>
                  <label>
                    badgesCommunity
                    <input type='checkbox' checked={showUserBadgesCommunity} onChange={() => setShowUserBadgesCommunity(prev => !prev)} />
                  </label>
                  <label>
                    badgesReferral
                    <input type='checkbox' checked={showUserBadgesReferral} onChange={() => setShowUserBadgesReferral(prev => !prev)} />
                  </label>
                </div>

                {/* Dashboard Users Logs */}
                <div className="table-wrapper">
                  <h3>Dashboard Users Logs</h3>
                <table className="table">
                  <thead>
                    <tr>
                      {showUserAddress && <th>address</th>}
                      {showUserId && <th>_id</th>}
                      {showUserActivated && <th>activated</th>}
                      {showUserActiveReferrals && <th>activeReferrals</th>}
                      {showUserIsKOL && <th>isKOL</th>}
                      {showUserLevel && <th>level</th>}
                      {showUserMultiplier && <th>multiplier</th>}
                      {showUserRank && <th>rank</th>}
                      {showUserRankPercent && <th>rankPercent</th>}
                      {showUserRefCode && <th>refCode</th>}
                      {showUserRewardedActiveReferrals && <th>rewardedActiveReferrals</th>}
                      {showUserRewardedWalletActivations && <th>rewardedWalletActivations</th>}
                      {showUserUserWalletPoints && <th>walletPoints</th>}
                      {showUserWalletsBalanceUsdValue && <th>walletsBalanceUsdValue</th>}
                      {showUserXp && <th>xp</th>}
                      {showUserXpMultiplier && <th>xpMultiplier</th>}
                      {showUserBadgesLiquidity && <th>badgesLiquidity</th>}
                      {showUserBadgesCommunity && <th>badgesCommunity</th>}
                      {showUserBadgesReferral && <th>badgesReferral</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      questUsersAndWalletsLogs?.users?.map(user => (
                        <tr key={user.address}>
                          {showUserAddress && <td>{user.address}</td>}
                          {showUserId && <td>{user._id}</td>}
                          {showUserActivated && <td>{user.activated}</td>}
                          {showUserActiveReferrals && <td>{user.activeReferrals}</td>}
                          {showUserIsKOL && <td>{user.isKOL}</td>}
                          {showUserLevel && <td>{user.level}</td>}
                          {showUserMultiplier && <td>{user.multiplier}</td>}
                          {showUserRank && <td>{user.rank}</td>}
                          {showUserRankPercent && <td>{user.rankPercent}</td>}
                          {showUserRefCode && <td>{user.refCode}</td>}
                          {showUserRewardedActiveReferrals && <td>{user.rewardedActiveReferrals}</td>}
                          {showUserRewardedWalletActivations && <td>{user.rewardedWalletActivations}</td>}
                          {showUserUserWalletPoints && <td>{user.walletPoints}</td>}
                          {showUserWalletsBalanceUsdValue && <td>{user.walletsBalanceUsdValue}</td>}
                          {showUserXp && <td>{user.xp}</td>}
                          {showUserXpMultiplier && <td>{user.xpMultiplier}</td>}
                          {showUserBadgesLiquidity && <td>{user.badges.liquidity?.id},{user.badges.liquidity?.givenAt}</td>}
                          {showUserBadgesCommunity && <td>{user.badges.community?.id},{user.badges.community?.givenAt}</td>}
                          {showUserBadgesReferral && <td>{user.badges.referral?.id},{user.badges.referral?.givenAt}</td>}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                </div>


                <div className="filters">
                  <label>
                    address
                    <input type='checkbox' checked={showWalletAddress} onChange={() => setShowWalletAddress(prev => !prev)} />
                  </label>
                  <label>
                    _id
                    <input type='checkbox' checked={showWalletId} onChange={() => setShowWalletId(prev => !prev)} />
                  </label>
                  <label>
                    currentOwner
                    <input type='checkbox' checked={showWalletCurrentOwner} onChange={() => setShowWalletCurrentOwner(prev => !prev)} />
                  </label>
                  <label>
                    activationRewarded
                    <input type='checkbox' checked={showWalletActivationRewarded} onChange={() => setShowWalletActivationRewarded(prev => !prev)} />
                  </label>
                  <label>
                    agentPoints
                    <input type='checkbox' checked={showWalletWalletAgentPoints} onChange={() => setShowWalletWalletAgentPoints(prev => !prev)} />
                  </label>
                  <label>
                    balanceUsdValue
                    <input type='checkbox' checked={showWalletBalanceUsdValue} onChange={() => setShowWalletBalanceUsdValue(prev => !prev)} />
                  </label>
                  <label>
                    nickname
                    <input type='checkbox' checked={showWalletNickname} onChange={() => setShowWalletNickname(prev => !prev)} />
                  </label>
                  <label>
                    rewardsProcesedBalance
                    <input type='checkbox' checked={showWalletRewardsProcessedBalance} onChange={() => setShowWalletRewardsProcessedBalance(prev => !prev)} />
                  </label>
                </div>

                {/* Dashboard Wallets Logs */}
                <div className="table-wrapper">
                  <h3>Dashboard Wallets Logs</h3>
                <table className="table">
                  <thead>
                    <tr>
                      {showWalletAddress && <th>address</th>}
                      {showWalletId && <th>_id</th>}
                      {showWalletCurrentOwner && <th>currentOwner</th>}
                      {showWalletActivationRewarded && <th>activationRewarded</th>}
                      {showWalletWalletAgentPoints && <th>agentPoints</th>}
                      {showWalletBalanceUsdValue && <th>balanceUsdValue</th>}
                      {showWalletNickname && <th>nickname</th>}
                      {showWalletRewardsProcessedBalance && <th>rewardsProcessedBalance</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      questUsersAndWalletsLogs?.wallets?.map(wallet => (
                        <tr key={wallet.address}>
                          {showWalletAddress && <td>{wallet.address}</td>}
                          {showWalletId && <td>{wallet._id}</td>}
                          {showWalletCurrentOwner && <td>{wallet.currentOwner}</td>}
                          {showWalletActivationRewarded && <td>{wallet.activationRewarded}</td>}
                          {showWalletWalletAgentPoints && <td>{wallet.agentPoints}</td>}
                          {showWalletBalanceUsdValue && <td>{wallet.balanceUsdValue}</td>}
                          {showWalletNickname && <td>{wallet.nickname}</td>}
                          {showWalletRewardsProcessedBalance && <td>{wallet.rewardsProcessedBalance}</td>}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                </div>


                <div className="filters">
                  <label>
                    user
                    <input type='checkbox' checked={showUser} onChange={() => setShowUser(prev => !prev)} />
                  </label>
                  <label>
                    wallet
                    <input type='checkbox' checked={showWallet} onChange={() => setShowWallet(prev => !prev)} />
                  </label>
                  <label>
                    _id
                    <input type='checkbox' checked={showId} onChange={() => setShowId(prev => !prev)} />
                  </label>
                  <label>
                    createdAt
                    <input type='checkbox' checked={showCreatedAt} onChange={() => setShowCreatedAt(prev => !prev)} />
                  </label>
                  <label>
                    function
                    <input type='checkbox' checked={showFunction} onChange={() => setShowFunction(prev => !prev)} />
                  </label>
                  <label>
                    balanceUsdValue
                    <input type='checkbox' checked={showBalanceUsdValue} onChange={() => setShowBalanceUsdValue(prev => !prev)} />
                  </label>
                  <label>
                    heldMinutes
                    <input type='checkbox' checked={showHeldMinutes} onChange={() => setShowHeldMinutes(prev => !prev)} />
                  </label>
                  <label>
                    heldMinutesGroups
                    <input type='checkbox' checked={showHeldMinutesGroups} onChange={() => setShowHeldMinutesGroups(prev => !prev)} />
                  </label>
                  <label>
                    heldSeconds
                    <input type='checkbox' checked={showHeldSeconds} onChange={() => setShowHeldSeconds(prev => !prev)} />
                  </label>
                  <label>
                    heldValue
                    <input type='checkbox' checked={showHeldValue} onChange={() => setShowHeldValue(prev => !prev)} />
                  </label>
                  <label>
                    heldValueGroups
                    <input type='checkbox' checked={showHeldValueGroups} onChange={() => setShowHeldValueGroups(prev => !prev)} />
                  </label>
                  <label>
                    lastChecked
                    <input type='checkbox' checked={showLastCheckedAt} onChange={() => setShowLastCheckedAt(prev => !prev)} />
                  </label>
                  <label>
                    newPoints
                    <input type='checkbox' checked={showNewPoints} onChange={() => setShowNewPoints(prev => !prev)} />
                  </label>
                  <label>
                    newXp
                    <input type='checkbox' checked={showNewXp} onChange={() => setShowNewXp(prev => !prev)} />
                  </label>
                  <label>
                    rewardsProcessedAt
                    <input type='checkbox' checked={showRewardsProcessedAt} onChange={() => setShowRewardsProcessedAt(prev => !prev)} />
                  </label>
                  <label>
                    rewardsProcessedBalance
                    <input type='checkbox' checked={showRewardsProcessedBalance} onChange={() => setShowRewardsProcessedBalance(prev => !prev)} />
                  </label>
                  <label>
                    userWalletPoints
                    <input type='checkbox' checked={showUserWalletPoints} onChange={() => setShowUserWalletPoints(prev => !prev)} />
                  </label>
                  <label>
                    walletAgentPoints
                    <input type='checkbox' checked={showWalletAgentPoints} onChange={() => setShowWalletAgentPoints(prev => !prev)} />
                  </label>
                  <label>
                    xp
                    <input type='checkbox' checked={showXp} onChange={() => setShowXp(prev => !prev)} />
                  </label>
                </div>

                {/* All Logs */}
                <div className="table-wrapper">
                  <h3>All Logs</h3>
                <table className="table">
                  <thead>
                    <tr>
                      {showUser && <th>user</th>}
                      {showWallet && <th>wallet</th>}
                      {showId && <th>_id</th>}
                      {showCreatedAt && <th>createdAt</th>}
                      {showFunction && <th>function</th>}
                      {showBalanceUsdValue && <th>balanceUsdValue</th>}
                      {showHeldMinutes && <th>heldMinutes</th>}
                      {showHeldMinutesGroups && <th>heldMinutesGroups</th>}
                      {showHeldSeconds && <th>heldSeconds</th>}
                      {showHeldValue && <th>heldValue</th>}
                      {showHeldValueGroups && <th>heldValueGroups</th>}
                      {showLastCheckedAt && <th>lastChecked</th>}
                      {showNewPoints && <th>newPoints</th>}
                      {showNewXp && <th>newXp</th>}
                      {showRewardsProcessedAt && <th>rewardsProcessedAt</th>}
                      {showRewardsProcessedBalance && <th>rewardsProcessedBalance</th>}
                      {showUserWalletPoints && <th>userWalletPoints</th>}
                      {showWalletAgentPoints && <th>walletAgentPoints</th>}
                      {showXp && <th>xp</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      allLogs.map(log => (
                        <tr key={log._id}>
                          {showUser && <td>{log.user}</td>}
                          {showWallet && <td>{log.wallet}</td>}
                          {showId && <td>{log._id}</td>}
                          {showCreatedAt && <td>{log.createdAt}</td>}
                          {showFunction && <td>{log.function}</td>}
                          {showBalanceUsdValue && <td>{log.input.balanceUsdValue}</td>}
                          {showHeldMinutes && <td>{log.input.heldMinutes}</td>}
                          {showHeldMinutesGroups && <td>{log.input.heldMinutesGroups}</td>}
                          {showHeldSeconds && <td>{log.input.heldSeconds}</td>}
                          {showHeldValue && <td>{log.input.heldValue}</td>}
                          {showHeldValueGroups && <td>{log.input.heldValueGroups}</td>}
                          {showLastCheckedAt && <td>{log.input.lastChecked}</td>}
                          {showNewPoints && <td>{log.output.newPoints}</td>}
                          {showNewXp && <td>{log.output.newXp}</td>}
                          {showRewardsProcessedAt && <td>{log.output.rewardsProcessedAt}</td>}
                          {showRewardsProcessedBalance && <td>{log.output.rewardsProcessedBalance}</td>}
                          {showUserWalletPoints && <td>{log.output.userWalletPoints}</td>}
                          {showWalletAgentPoints && <td>{log.output.walletAgentPoints}</td>}
                          {showXp && <td>{log.output.xp}</td>}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                </div>

                {/* User Logs */}
                <div className="table-wrapper">
                  <h3>User Logs</h3>
                  <div className="user-search">
                    <input type="text" value={userToSearch} onChange={(e) => setUserToSearch(e.target.value)} />
                    <button onClick={() => getUserLogs()}>Search</button>
                  </div>
                <table className="table">
                  <thead>
                    <tr>
                      {showUser && <th>user</th>}
                      {showWallet && <th>wallet</th>}
                      {showId && <th>_id</th>}
                      {showCreatedAt && <th>createdAt</th>}
                      {showFunction && <th>function</th>}
                      {showBalanceUsdValue && <th>balanceUsdValue</th>}
                      {showHeldMinutes && <th>heldMinutes</th>}
                      {showHeldMinutesGroups && <th>heldMinutesGroups</th>}
                      {showHeldSeconds && <th>heldSeconds</th>}
                      {showHeldValue && <th>heldValue</th>}
                      {showHeldValueGroups && <th>heldValueGroups</th>}
                      {showLastCheckedAt && <th>lastChecked</th>}
                      {showNewPoints && <th>newPoints</th>}
                      {showNewXp && <th>newXp</th>}
                      {showRewardsProcessedAt && <th>rewardsProcessedAt</th>}
                      {showRewardsProcessedBalance && <th>rewardsProcessedBalance</th>}
                      {showUserWalletPoints && <th>userWalletPoints</th>}
                      {showWalletAgentPoints && <th>walletAgentPoints</th>}
                      {showXp && <th>xp</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userLogs.map(log => (
                        <tr key={log._id}>
                          {showUser && <td>{log.user}</td>}
                          {showWallet && <td>{log.wallet}</td>}
                          {showId && <td>{log._id}</td>}
                          {showCreatedAt && <td>{log.createdAt}</td>}
                          {showFunction && <td>{log.function}</td>}
                          {showBalanceUsdValue && <td>{log.input.balanceUsdValue}</td>}
                          {showHeldMinutes && <td>{log.input.heldMinutes}</td>}
                          {showHeldMinutesGroups && <td>{log.input.heldMinutesGroups}</td>}
                          {showHeldSeconds && <td>{log.input.heldSeconds}</td>}
                          {showHeldValue && <td>{log.input.heldValue}</td>}
                          {showHeldValueGroups && <td>{log.input.heldValueGroups}</td>}
                          {showLastCheckedAt && <td>{log.input.lastChecked}</td>}
                          {showNewPoints && <td>{log.output.newPoints}</td>}
                          {showNewXp && <td>{log.output.newXp}</td>}
                          {showRewardsProcessedAt && <td>{log.output.rewardsProcessedAt}</td>}
                          {showRewardsProcessedBalance && <td>{log.output.rewardsProcessedBalance}</td>}
                          {showUserWalletPoints && <td>{log.output.userWalletPoints}</td>}
                          {showWalletAgentPoints && <td>{log.output.walletAgentPoints}</td>}
                          {showXp && <td>{log.output.xp}</td>}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                </div>

                {/* Wallet Logs */}
                <div className="table-wrapper">
                  <h3>Wallet Logs</h3>
                  <div className="wallet-search">
                    <input type="text" value={walletToSearch} onChange={(e) => setWalletToSearch(e.target.value)} />
                    <button onClick={() => getWalletLogs()}>Search</button>
                  </div>
                <table className="table">
                  <thead>
                    <tr>
                      {showUser && <th>user</th>}
                      {showWallet && <th>wallet</th>}
                      {showId && <th>_id</th>}
                      {showCreatedAt && <th>createdAt</th>}
                      {showFunction && <th>function</th>}
                      {showBalanceUsdValue && <th>balanceUsdValue</th>}
                      {showHeldMinutes && <th>heldMinutes</th>}
                      {showHeldMinutesGroups && <th>heldMinutesGroups</th>}
                      {showHeldSeconds && <th>heldSeconds</th>}
                      {showHeldValue && <th>heldValue</th>}
                      {showHeldValueGroups && <th>heldValueGroups</th>}
                      {showLastCheckedAt && <th>lastChecked</th>}
                      {showNewPoints && <th>newPoints</th>}
                      {showNewXp && <th>newXp</th>}
                      {showRewardsProcessedAt && <th>rewardsProcessedAt</th>}
                      {showRewardsProcessedBalance && <th>rewardsProcessedBalance</th>}
                      {showUserWalletPoints && <th>userWalletPoints</th>}
                      {showWalletAgentPoints && <th>walletAgentPoints</th>}
                      {showXp && <th>xp</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      walletLogs.map(log => (
                        <tr key={log._id}>
                          {showUser && <td>{log.user}</td>}
                          {showWallet && <td>{log.wallet}</td>}
                          {showId && <td>{log._id}</td>}
                          {showCreatedAt && <td>{log.createdAt}</td>}
                          {showFunction && <td>{log.function}</td>}
                          {showBalanceUsdValue && <td>{log.input.balanceUsdValue}</td>}
                          {showHeldMinutes && <td>{log.input.heldMinutes}</td>}
                          {showHeldMinutesGroups && <td>{log.input.heldMinutesGroups}</td>}
                          {showHeldSeconds && <td>{log.input.heldSeconds}</td>}
                          {showHeldValue && <td>{log.input.heldValue}</td>}
                          {showHeldValueGroups && <td>{log.input.heldValueGroups}</td>}
                          {showLastCheckedAt && <td>{log.input.lastChecked}</td>}
                          {showNewPoints && <td>{log.output.newPoints}</td>}
                          {showNewXp && <td>{log.output.newXp}</td>}
                          {showRewardsProcessedAt && <td>{log.output.rewardsProcessedAt}</td>}
                          {showRewardsProcessedBalance && <td>{log.output.rewardsProcessedBalance}</td>}
                          {showUserWalletPoints && <td>{log.output.userWalletPoints}</td>}
                          {showWalletAgentPoints && <td>{log.output.walletAgentPoints}</td>}
                          {showXp && <td>{log.output.xp}</td>}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                </div>
              </div>
            ) : (
              <div className="wrapper-columns">
                <div className="dashboard-users-logs">
            <h3>Dashboard Users Logs</h3>
            {questUsersAndWalletsLogs?.users?.map(user => (
              <div key={user.address} className="log">
                <p>address: {shortAddress(user.address)}</p>
                <p>_id: {user._id}</p>
                <p>activated: {user.activated}</p>
                <p>activeReferrals: {user.activeReferrals}</p>
                <p>isKOL: {user.isKOL}</p>
                <p>level: {user.level}</p>
                <p>multiplier: {user.multiplier}</p>
                <p>rank: {user.rank}</p>
                <p>rankPercent: {user.rankPercent}</p>
                <p>refCode: {user.refCode}</p>
                <p>rewardedActiveReferrals: {user.rewardedActiveReferrals}</p>
                <p>rewardedWalletActivations: {user.rewardedWalletActivations}</p>
                <p>walletPoints: {user.walletPoints}</p>
                <p>walletsBalanceUsdValue: {user.walletsBalanceUsdValue}</p>
                <p>xp: {user.xp}</p>
                <p>xpMultiplier: {user.xpMultiplier}</p>
                <p>badgesLiquidity: {user.badges.liquidity?.id}, {user.badges.liquidity?.givenAt}</p>
                <p>badgesCommunity: {user.badges.community?.id}, {user.badges.community?.givenAt}</p>
                <p>badgesReferral: {user.badges.referral?.id}, {user.badges.referral?.givenAt}</p>
              </div>
            ))}
          </div>
          <div className="dashboard-wallets-logs">
            <h3>Dashboard Wallets Logs</h3>
            {questUsersAndWalletsLogs?.wallets?.map(wallet => (
              <div key={wallet.address} className="log">
                <p>address: {shortAddress(wallet.address)}</p>
                <p>_id: {wallet._id}</p>
                <p>currentOwner: {shortAddress(wallet.currentOwner)}</p>
                <p>activationRewarded: {wallet.activationRewarded}</p>
                <p>agentPoints: {wallet.agentPoints}</p>
                <p>balanceUsdValue: {wallet.balanceUsdValue}</p>
                <p>nickname: {wallet.nickname}</p>
                <p>rewardsProcessedBalance: {wallet.rewardsProcesedBalance}</p>
              </div>
            ))}
          </div>
          <div className="all-logs">
            <h3>All Logs</h3>
            {allLogs.map(log => (
              <div key={log._id} className="log">
                <p title='123'>user: {shortAddress(log.user)}</p>
                <p>wallet: {shortAddress(log.wallet)}</p>
                <p>_id: {log._id}</p>
                <p>createdAt: {log.createdAt}</p>
                <p>function: {log.function}</p>
                <p>input:</p>
                <div className="log-input">
                  <p>balanceUsdValue: {log.input.balanceUsdValue}</p>
                  <p>heldMinutes: {log.input.heldMinutes}</p>
                  <p>heldMinutesGroups: {log.input.heldMinutesGroups}</p>
                  <p>heldSeconds: {log.input.heldSeconds}</p>
                  <p>heldValue: {log.input.heldValue}</p>
                  <p>heldValueGroups: {log.input.heldValueGroups}</p>
                  <p>lastChecked: {log.input.lastChecked}</p>
                </div>
                <p>output:</p>
                <div className="log-output">
                  <p>newPoints: {log.output.newPoints}</p>
                  <p>newXp: {log.output.newXp}</p>
                  <p>rewardsProcessedAt: {log.output.rewardsProcessedAt}</p>
                  <p>rewardsProcessedBalance: {log.output.rewardsProcessedBalance}</p>
                  <p>userWalletPoints: {log.output.userWalletPoints}</p>
                  <p>walletAgentPoints: {log.output.walletAgentPoints}</p>
                  <p>xp: {log.output.xp}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="user-logs">
            <h3>User Logs</h3>
            <div className="user-search">
              <input type="text" value={userToSearch} onChange={(e) => setUserToSearch(e.target.value)} />
              <button onClick={() => getUserLogs()}>Search</button>
            </div>
            {userLogs.map(log => (
              <div key={log._id} className="log">
                <p>user: {shortAddress(log.user)}</p>
                <p>wallet: {shortAddress(log.wallet)}</p>
                <p>_id: {log._id}</p>
                <p>createdAt: {log.createdAt}</p>
                <p>function: {log.function}</p>
                <p>input:</p>
                <div className="log-input">
                  <p>balanceUsdValue: {log.input.balanceUsdValue}</p>
                  <p>heldMinutes: {log.input.heldMinutes}</p>
                  <p>heldMinutesGroups: {log.input.heldMinutesGroups}</p>
                  <p>heldSeconds: {log.input.heldSeconds}</p>
                  <p>heldValue: {log.input.heldValue}</p>
                  <p>heldValueGroups: {log.input.heldValueGroups}</p>
                  <p>lastChecked: {log.input.lastChecked}</p>
                </div>
                <p>output:</p>
                <div className="log-output">
                  <p>newPoints: {log.output.newPoints}</p>
                  <p>newXp: {log.output.newXp}</p>
                  <p>rewardsProcessedAt: {log.output.rewardsProcessedAt}</p>
                  <p>rewardsProcessedBalance: {log.output.rewardsProcessedBalance}</p>
                  <p>userWalletPoints: {log.output.userWalletPoints}</p>
                  <p>walletAgentPoints: {log.output.walletAgentPoints}</p>
                  <p>xp: {log.output.xp}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="wallet-logs">
            <h3>Wallet Logs</h3>
            <div className="wallet-search">
              <input type="text" value={walletToSearch} onChange={(e) => setWalletToSearch(e.target.value)} />
              <button onClick={() => getWalletLogs()}>Search</button>
            </div>
            {walletLogs.map(log => (
              <div key={log._id} className="log">
                <p>user: {shortAddress(log.user)}</p>
                <p>wallet: {shortAddress(log.wallet)}</p>
                <p>_id: {log._id}</p>
                <p>createdAt: {log.createdAt}</p>
                <p>function: {log.function}</p>
                <p>input:</p>
                <div className="log-input">
                  <p>balanceUsdValue: {log.input.balanceUsdValue}</p>
                  <p>heldMinutes: {log.input.heldMinutes}</p>
                  <p>heldMinutesGroups: {log.input.heldMinutesGroups}</p>
                  <p>heldSeconds: {log.input.heldSeconds}</p>
                  <p>heldValue: {log.input.heldValue}</p>
                  <p>heldValueGroups: {log.input.heldValueGroups}</p>
                  <p>lastChecked: {log.input.lastChecked}</p>
                </div>
                <p>output:</p>
                <div className="log-output">
                  <p>newPoints: {log.output.newPoints}</p>
                  <p>newXp: {log.output.newXp}</p>
                  <p>rewardsProcessedAt: {log.output.rewardsProcessedAt}</p>
                  <p>rewardsProcessedBalance: {log.output.rewardsProcessedBalance}</p>
                  <p>userWalletPoints: {log.output.userWalletPoints}</p>
                  <p>walletAgentPoints: {log.output.walletAgentPoints}</p>
                  <p>xp: {log.output.xp}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
            )
          }
      </div>
    )

}

export default OldUI

const shortAddress = addr => { return addr ? `${addr?.slice(0,6)}...${addr?.slice(-4)}` : '' }